<template>
  <div
    class="w-full p-3 mb-3 rounded-lg bg-boxtxt-dark shadow-md text-center text-txt-light text-sm font-sans font-light"
  >
    <!--
    <font-awesome-icon
      :icon="['fas', 'box-open']"
      class="text-2xl text-txt-light"
    />
    -->
    <slot></slot>
  </div>
</template>
